import React from 'react';
import { Link } from 'react-router-dom';
import './Cards4.css';





function Cards4() {
  return (
    <div className='cards4'>
      
      <h1>AGENCIAS DE APOYO</h1>
      <p>HWASA aprecia el apoyo de las siguientes instituciones</p>

      <Link
className='mccsa-logo-link'
to={{ pathname: "https://mccsa.org.au/" }}
              target='_blank'
              rel='noopener' // good security practice
              aria-label='Mccsa'
              >

<img src='/images/MCCSA-Logo.png' alt='Logo'  className='mccsa-logo-link' loading='auto' width="597" height="120"/>

  
</Link>  


<Link
className='mccsa-logo-link'
to={{ pathname: "https://purpleorange.org.au/" }}
              target='_blank'
              rel='noopener' // good security practice
              aria-label='Purple Orange'
              >

<img src='/images/purpleorange-logo.png' alt='purpleorange-logo'className='mccsa-logo-link' loading='auto'/>

  
</Link>   
 
     

      



</div>

    
   

     







  
  );
}

export default Cards4;
